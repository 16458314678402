<template>
  <vp-base-layout page-title="접수최종 확인">
    <div class="l-wrapper">
      <div
        class="l-contents"
        style="margin: 50px 20px 50px 20px;">
        <p>선택하신 정보가 맞는지 마지막으로 확인해주세요.</p>
        <ion-item style="margin-top: 50px">
          <ion-label>● 신청자 : </ion-label>
          <ion-input v-model="data.order_nm" readonly />
        </ion-item>
        <ion-item>
          <ion-label>● 전화번호 : </ion-label>
          <ion-input v-model="data.order_tel_no" readonly />
        </ion-item>
        <ion-item>
          <ion-label>● 은행 : </ion-label>
          <ion-input v-model="data.bank_nm" readonly />
        </ion-item>
        <ion-item>
          <ion-label>● 예금주 : </ion-label>
          <ion-input v-model="data.acc_nm" readonly />
        </ion-item>
        <ion-item>
          <ion-label>● 계좌번호 : </ion-label>
          <ion-input v-model="data.acc_num" readonly />
        </ion-item>
        <ion-item>
          <ion-label>● 비고(모델) : </ion-label>
          <ion-input v-model="data.description" readonly />
        </ion-item>
      </div>
      <div class="card-button">
        <vp-button
          expand="full"
          @click="handlePage(data)">
          <span class="amount"></span> 접수완료 하기
        </vp-button>
      </div>
    </div>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { useStore } from 'vuex'
import { computed, onMounted, inject } from 'vue'
import {
  IonLabel,
  IonInput,
  IonItem,
  useIonRouter,
  alertController
} from '@ionic/vue'

import VpPageTitle from '@/components//original/layouts/VpPageTitle.vue'
import { listData, addReceipt } from '@/api/mintit/agency'
import { encrypt } from '@/utils/jsencrypt'

const store = useStore()
const ionRouter = useIonRouter()
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const data = computed(() => store.state.Customer)
const dayjs = inject('$dayjs')

const handleCheck = async(item) => {
    var str = ''
    if(item.bank_code == '') {
      str = '은행정보가 없습니다.'
    } else if(item.acc_nm == '') {
      str = '예금주가 없습니다.'
    } else if(item.acc_num == '') {
      str = '계좌정보가 없습니다.'
    } else if(item.order_tel_no == '') {
      str = '입력된 전화번호가 없습니다.'
    } else if(item.order_tel_no == '') {
      str = '입력된 전화번호가 없습니다.'
    }

    const alert = await alertController.create({
      //header: '약관동의',
      //subHeader: 'Important message',
      message: str,
      buttons: ['확인']
    })
    await alert.present()
}

const handlePage = async (item) => {  
  if(item.bank_code == '' || item.acc_nm == '' || item.acc_num == '' || item.order_tel_no == '') {
    handleCheck(item)
    return
  }

  //최종접수 저장
  const param = {}
  param.order_state_code = '1'
  param.order_date = dayjs().format('YYYY-MM-DD HH:mm:ss')
  param.acc_state_code = '1'
  param.agree1_yn = 'Y' //동의한걸로 간주
  param.agree2_yn = 'Y' //동의한걸로 간주
  param.order_nm = item.order_nm
  param.order_tel_no = item.order_tel_no
  param.bank_nm = item.bank_nm
  param.acc_nm = item.acc_nm
  param.description = item.description
  param.acc_num = encrypt(item.acc_num)
  await addReceipt(param)
  // ionRouter.replace('/')
  location.href = '/'
}

const queryParams = {
  pageNum: 1,
  pageSize: 9999,
  dictName: undefined,
  dictType: undefined,
  dictValue: undefined,
  status: undefined
}

const getBankName = async () => {  
  queryParams.dictType = 'bank_list'
  queryParams.dictValue = data.value.bank_code
  const response = await listData(queryParams)
  data.value.bank_nm = response.data.results[0].dictLabel
}
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  getBankName()
})
</script>

<style scoped>
ion-select{
    max-width: 80% !important;
}
.fixedLabel {
    min-width: 30% !important;
    max-width: 30% !important;
}
</style>